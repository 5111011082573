<template>
  <div class="xl:fixed w-full bottom-6 sm:px-3 xl:px-12 flex flex-col gap-3.5 sm:flex-row justify-between items-center max-w-7xl">
    <div class="flex gap-2">
      <LandingSocial icon="twitter-logo" color="sky-5" url="https://twitter.com/trytilt" />
      <LandingSocial icon="tiktok-logo" color="red-5" url="https://www.tiktok.com/@tilt.app" />
      <LandingSocial icon="instagram-logo" color="candy-pink-5" url="https://www.instagram.com/tilt" />
    </div>
    <div class="flex gap-4 sm:gap-8">
      <NuxtLink to="https://jobs.ashbyhq.com/tilt" class="text-sm text-neutral-4 hover:text-neutral-5 hover:underline">Careers</NuxtLink>
      <NuxtLink to="https://support.tilt.app/" class="text-sm text-neutral-4 hover:text-neutral-5 hover:underline">Tilt Support</NuxtLink>
      <NuxtLink to="/terms" class="text-sm text-neutral-4 hover:text-neutral-5 hover:underline">Terms and Conditions</NuxtLink>
      <NuxtLink to="/privacy" class="text-sm text-neutral-4 hover:text-neutral-5 hover:underline">Privacy Policy</NuxtLink>
      <NuxtLink to="/cookies" class="text-sm text-neutral-4 hover:text-neutral-5 hover:underline">Cookie Policy</NuxtLink>
    </div>
  </div>
</template>